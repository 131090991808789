import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import Details from "./Details/Details";
import Images from "./Images/Images";
import Impressum from "./Impressum/Impressum";
import Home from "./Home/Home";
import history from './history';

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/details/:id" component={Details} />
                    <Route path="/images/:id" component={Images} />
                    <Route path="/impressum/" component={Impressum} />
                </Switch>
            </Router>
        )
    }
}