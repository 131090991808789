import React, { Component } from "react";
import jsonp from './../jsonp';
import history from './../history';
import {
  Link,
  HashRouter
} from "react-router-dom";
import PostWall from './../components/PostWall';
import LightboxGallery from './../Gallery/LightboxGallery';


class Home extends Component {

  constructor() {
    super();
    this.state = { width: -1 };
    this.loadPhotos = this.loadPhotos.bind(this);

  }
  componentDidMount() {


    this.loadPhotos();


  }

  loadPhotos() {

  }



  render() {


    return (




    	<main role="main">

      <section className="jumbotron text-center">
        <div className="container">
          <div className="jumbo-content">
            <h1 className="jumbotron-heading">Herzlich Willkommen</h1>
            <p className="lead text-muted"></p>
            <p>
              <a href="#" className="btn btn-primary my-2">Neue Beiträge</a>
              <div className="btn-group">
                <button type="button" className="btn btn-primary my-2" onClick={() => history.push( { pathname: '/images/' + 'tags=all'} )}>Galerie</button>
              </div>

            </p>
          </div>
        </div>
      </section>

      

      <div className="album py-5 bg-light">
        <div className="container">

          
            <PostWall />

            
           
          
        </div>
      </div>

    </main>


    	
    	);
}
}

export default Home;