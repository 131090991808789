import React from 'react';
import {
  Link,
  HashRouter
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Routes from './Routes';



function App() {
  return (

<div>

  <Navbar/>

  <Routes/>


    
    </div>
  );
}

export default App;
