import React, { Component } from "react";
import history from './../history';

class Navbar extends Component {
	render(){
		return(

			<header>
			<div className="container">
			<div className="row">
			  <div className="navbar navbar-dark bg-dark fixed-top">
			    <div className="container d-flex justify-content-between">
			      <a href="http://floriannass.de" className="navbar-brand d-flex align-items-center" onClick={() => history.push('/')}>
			        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
			          <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"/>
			          <circle cx="12" cy="13" r="4"/>
			        </svg>
			        <img src="/img/floriannass.png" alt="Florian Nass" width="100px"/>
			      </a>
			      {
			      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
			        <span className="navbar-toggler-icon"/>
			      </button>
			  		}
			    </div>
			    </div>
			    <div className="collapse bg-dark" id="navbarHeader">
			      
			        <div className="row">
			          <div className="col-sm-8 col-md-7 py-4">
			            <h4 className="text-white">Über mich</h4>
			            <p className="text-muted">Florian Nass: Natur- und Landschaftsfotografie.<br/> Bei Interesse an Fotos in hochqualitativer Auflösung für Web und Print kontaktieren Sie mich bitte per Email.</p>
			          </div>
			          <div className="col-sm-4 offset-md-1 py-4">
			            <h4 className="text-white">Kontakt</h4>
			            <ul className="list-unstyled">
			              <li>
			                <a href="https://instagram.com/flonassphoto" className="text-white" target="_blank">Mein Instagram</a>
			              </li>
			              <li>
			                <a href="mailto:info@FlorianNass.de" className="text-white" target="_blank">info@FlorianNass.de</a>
			              </li>
			            </ul>
			            <span className="text-white"  style={{cursor : 'pointer', textDecoration : 'underline'}} onClick={() => history.push( { pathname: '/impressum/'} )}>Impressum</span>
			          </div>

			        </div>
			      </div>
			    </div>
			  </div>
			</header>

			);

	}

}

export default Navbar;