import React, { Component } from "react";
import history from './../history';
import {
  Link,
  HashRouter
} from "react-router-dom";
import LightboxGallery from './../Gallery/LightboxGallery';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import InfoWindowEx from "./InfoWindowEx"

class Images extends Component {



constructor(props){
    super(props);


    this.state = {
      id: "" ,
      info: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }


onMarkerClick = (props, marker, e) => {
  console.log(props.name);
    this.setState({
      selectedPlace: props.name,
      thumb_url: props.thumb_url,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };


onMapClick = (props, marker, e) => {
  
    this.setState({
      showingInfoWindow: false
    });
  };




 fetchImages = tag => {

    console.log(this.state.id);
    var oldState = this.state.id.split(",");
    var newState = [];

    console.log(oldState);

    if(oldState[0] == 'all'){

      newState.push(tag);


    }else{
      if(oldState.includes(tag)){
        oldState.splice(oldState.indexOf(tag), 1);

      }else{
        oldState.push(tag);
      }

      newState = oldState;

    }


    var newID = newState.toString();
    
    if(newID=="") newID="all";


    this.setState({id : newID}, () => {

      console.log(this.state.id);

    fetch(process.env.PUBLIC_URL+'./../api/getImages/?tags='+this.state.id, {
    //fetch('http://localhost/api/getImages/?tags='+this.state.id, { 
      method: 'no-cors'
    }
      )
      .then(response => response.json())
      .then(data => this.setState( {info : data}));


      window.history.pushState(this.state.id, "Florian Nass - Bilder von " + this.state.id, "/images/tags="+this.state.id);


    });

    

    


  };



  componentDidMount() {

    var url = this.props.match.params.id;
    var id = url.substring(url.lastIndexOf("=") + 1 ,url.length);
    this.setState({id : id});

    

     fetch(process.env.PUBLIC_URL+'./../api/getImages/?tags='+id, { 
     //fetch('http://localhost/api/getImages/?tags='+id, { 
      method: 'no-cors'
    }
      )
      .then(response => response.json())
      .then(data => this.setState( {info : data}));


  }


componentDidUpdate(){



  
}




  render() {



    const google = window.google;
    

    if(typeof this.state.info.images === 'undefined') return null;
    
 
    var imagesList = this.state.info.images.map(function(image){

      image.src = process.env.PUBLIC_URL + "./../img/" + image.path_large;
      image.title = image.focal_length + "  |  " +  image.aperture + "  |  " +  image.exposure + "  |  " +  image.iso;
      image.srcset = process.env.PUBLIC_URL + "./../img/" + image.path_medium;


    });


    var markerList = this.state.info.images.map(function(image){

  
        return(
         
            <Marker title={'Fotospot'} name={'Fotospot ' + image.image_id } position={{lat: image.lat, lng: image.lon}} onClick={this.onMarkerClick} key={image.image_id} thumb_url={process.env.PUBLIC_URL + "./../img/" + image.path_thumb}  icon={{
        url: process.env.PUBLIC_URL + "./../img/" + image.path_thumb,
        anchor: new google.maps.Point(32,32),
        scaledSize: new google.maps.Size(77,50) }} />
      

                    )


    }, this)


    //build tag buttons
    var tagList = this.state.info.tags.map(function(tag){

      return (
             <button className="btn btn-secondary" onClick={this.fetchImages.bind(this.fetchImages,tag.tag)}>{tag.tag}</button>
            )
    }, this)


    
    return (
      <main role="main">

      <section className="jumbotron text-center">
        <div className="container">
        <div className="jumbo-content">
          <h1 className="jumbotron-heading">Galerie</h1>
          <p></p>
          <p></p>
          <p>
            
          </p>
          </div>
        </div>
      </section>

      <div className="album py-5 bg-light">
        <div className="container">

          <div className="row">

            <div className="col-md-12">

            <div class="btn-group-toggle" data-toggle="buttons">
              {tagList}
            </div>


              
              
            </div>
          </div>

          <div className="row">
            <div id="mapBox" className="col-md-12 imagesMapBox" height="500px">
              <Map onClick={this.onMapClick} ref={(ref) => {this.mapRef = ref;}} center={{lat: this.state.info.center_lat, lng: this.state.info.center_lon}} initialCenter={{lat: this.state.info.center_lat, lng: this.state.info.center_lon}} google={this.props.google} zoom={this.state.info.zoom} styles={[{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#000000"},{"lightness":13}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#555555"},{"lightness":14},{"weight":1.4}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#55595c"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#242526"},{"lightness":5}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":25}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"transit","elementType":"all","stylers":[{"color":"#ffffff"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#18191A"}]}]
}> 
                  {markerList}
                  <InfoWindowEx marker={this.state.activeMarker} visible={this.state.showingInfoWindow} >
                    <div>
                      <img src={this.state.thumb_url} width="232" height="150"/>
                    </div>
                  </InfoWindowEx>
              </Map>
            </div>
          </div>
          
        
          <div className="row imgcontainer"> 
            <div className="col-md-12">
              <LightboxGallery photos={this.state.info.images} style="padding: 15px"/> 
            </div>
          </div>

          

          </div>
        </div>
     

    </main>


    );
  }
}
 
export default GoogleApiWrapper({
 apiKey: ('AIzaSyCvAG-y_4j6mftVrDpEXuY08SdvQYPWb_0')
})(Images);