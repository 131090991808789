import React, { Component } from "react";
import history from './../history';
import {
  Link,
  HashRouter
} from "react-router-dom";
import LightboxGallery from './../Gallery/LightboxGallery';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import {Helmet} from "react-helmet";


class Impressum extends Component {



constructor(props){
    super(props);

    this.state = {
      id: "" ,
      info: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }




  componentDidMount() {



  }


  render() {





  


    
    return (
      <main role="main">
      <Helmet>
            <title>{"Impressum - Florian Nass Fotografie"}</title>
            <meta name="description" content={this.state.info.text} />
        </Helmet>
       
      <section className="jumbotron text-center">
        <div className="container">
        <div className="jumbo-content">
          <h1 className="jumbotron-heading">Impressum</h1>
          <p></p>
          <p></p>
          <p>
            <button className="btn btn-primary my-2" onClick={this.props.history.goBack}>Zurück</button>
          </p>
          </div>
        </div>
      </section>

      <div className="album py-5 bg-light">
        <div className="container">

          
        
          <div className="row"> 
            <div className="col-md-12">
              
              <p className="text-white">
                

Florian Nass<br/>
Austrasse 4<br/>
85435 Erding<br/>
Deutschland<br/><br/>

Tel.: 0151 22933210<br/>
E-Mail: info@FlorianNass.de<br/>
www.FlorianNass.de<br/>
<br/>

Berufsbezeichnung: Freiberuflicher Fotograf für Natur- und Landschaftsaufnahmen
              </p>

              <p className="text-white">
              Haftungshinweis<br/>
Trotz sorgfältiger inhaltlicher Kontrolle übernimmt Florian Nass keine Haftung für Inhalte externer Links. Für den Inhalt verlinkter Seiten sind ausschließlich deren Betreiber verantwortlich.
              </p>



            </div>
          </div>


          </div>
        </div>
     

    </main>


    );
  }
}
 
export default Impressum;