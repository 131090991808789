import React, { Component } from "react";
import history from './../history';

class PostWall extends Component {


	constructor(props){
    super(props);

    this.state = {
      posts: [],
    };
  }

  componentDidMount() {

     fetch(process.env.PUBLIC_URL+'/api/getPosts/', {
    //fetch('http://localhost/api/getPosts/', { 
      method: 'no-cors'
    }
      )
      .then(response => response.json())
      .then(data => this.setState( {posts : data}));

  }



	render(){
		const { error, isLoaded, data } = this.state;


		/* build cards */
		var postsList = this.state.posts.map(function(post){

      var titlelink = post.title.replace(/\s/g, '-');
      var monatname = new Array("Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember");
      var date = post.date.split('-');
      console.log('#####' + date[1]);
      if(date[1] != '10'){
        date[1] = date[1].replace('0','');
      }
      var dateStr = date[2] + ". " + monatname[date[1]-1] + " " + date[0];



			return (
			       <div className="col-md-4">
              <div className="card mb-4 box-shadow">
                <img className="card-img-top" src={process.env.PUBLIC_URL + "/img/" + post.path_thumb} height="225" alt="Card image cap"/>
                <div className="card-body">
                  <p className="card-text">{post.title}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => history.push( { pathname: '/details/' + titlelink + '-' + post.id } )}><strong>Mehr...</strong></button>
                    </div>
                    <small className="text-muted">{dateStr}</small>
                  </div>
                </div>
              </div>
             </div> 
            )
		})


		return(

			<div className="row">{ postsList } </div>

			);

	}

}

export default PostWall;