import React, { Component } from "react";
import history from './../history';
import {
  Link,
  HashRouter
} from "react-router-dom";
import LightboxGallery from './../Gallery/LightboxGallery';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import InfoWindowEx from "./InfoWindowEx";
import {Helmet} from "react-helmet";


class Details extends Component {



constructor(props){
    super(props);

    this.state = {
      id: "" ,
      info: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }


onMarkerClick = (props, marker, e) => {
  console.log(props.name);
    this.setState({
      selectedPlace: props.name,
      thumb_url: props.thumb_url,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };


onMapClick = (props, marker, e) => {
  
    this.setState({
      showingInfoWindow: false
    });
  };



  componentDidMount() {

    var url = this.props.match.params.id;
    var id = url.substring(url.lastIndexOf("-") + 1 ,url.length);
    this.setState({id : id});

     fetch(process.env.PUBLIC_URL+'./../api/getPost/?id='+id, {
     //fetch('http://localhost/api/getPost/?id='+id, { 
      method: 'no-cors'
    }
      )
      .then(response => response.json())
      .then(data => this.setState( {info : data}));



  }


  render() {


    const google = window.google;
    

    if(typeof this.state.info.images === 'undefined') return null;

    var monatname = new Array("Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember");
    var date = this.state.info.date.split('-');
    if(date[1] != '10'){
      date[1] = date[1].replace('0','');
    }
    var dateStr = date[2] + ". " + monatname[date[1]-1] + " " + date[0];

    
 
    var imagesList = this.state.info.images.map(function(image){

      image.src = process.env.PUBLIC_URL + "./../img/" + image.path_large;
      image.title = image.focal_length + "  |  " +  image.aperture + "  |  " +  image.exposure + "  |  " +  image.iso;
      image.srcset = process.env.PUBLIC_URL + "./../img/" + image.path_medium;

      console.log("width:" + image.width);

    });


    var markerList = this.state.info.images.map(function(image){

  
        return(
         
            <Marker title={'Fotospot'} name={'Fotospot ' + image.image_id } position={{lat: image.lat, lng: image.lon}} onClick={this.onMarkerClick} key={image.image_id} thumb_url={process.env.PUBLIC_URL + "./../img/" + image.path_thumb}  icon={{
        url: process.env.PUBLIC_URL + "./../img/" + image.path_thumb,
        anchor: new google.maps.Point(32,32),
        scaledSize: new google.maps.Size(77,50) }} />
      

                    )


    }, this)


    
    return (
      <main role="main">
      <Helmet>
            <title>{this.state.info.title + " - Florian Nass Fotografie"}</title>
            <meta name="description" content={this.state.info.text} />
        </Helmet>
       
      <section className="jumbotron text-center">
        <div className="container">
        <div className="jumbo-content">
          <h1 className="jumbotron-heading">{this.state.info.title}</h1>
          <p>{dateStr}</p>
          <p>{this.state.info.text}</p>
          <p>
            <button className="btn btn-primary my-2" onClick={this.props.history.goBack}>Zurück</button>
          </p>
          </div>
        </div>
      </section>

      <div className="album py-5 bg-light">
        <div className="container">

          
        
          <div className="row"> 
            <div className="col-md-12">
              <LightboxGallery photos={this.state.info.images} style="padding: 15px"/> 
            </div>
          </div>

          <div className="row">
            <div id="mapBox" className="col-md-12" height="500px">
              <Map onClick={this.onMapClick} initialCenter={{lat: this.state.info.center_lat, lng: this.state.info.center_lon}} google={this.props.google} zoom={this.state.info.zoom} styles={[{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#000000"},{"lightness":13}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#555555"},{"lightness":14},{"weight":1.4}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#55595c"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#242526"},{"lightness":5}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":25}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"transit","elementType":"all","stylers":[{"color":"#ffffff"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#18191A"}]}]
}> 
                  {markerList}
                  <InfoWindowEx marker={this.state.activeMarker} visible={this.state.showingInfoWindow} >
                    <div>
                      <img src={this.state.thumb_url} width="232" height="150"/>
                    </div>
                  </InfoWindowEx>
              </Map>
            </div>
          </div>

          </div>
        </div>
     

    </main>


    );
  }
}
 
export default GoogleApiWrapper({
 apiKey: ('AIzaSyCvAG-y_4j6mftVrDpEXuY08SdvQYPWb_0')
})(Details);